import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                   <li>
                       <img src="/assets/images/brand/spring.svg" alt="Logo Images"/>
                   </li>
                   <li>
                       <img src="/assets/images/brand/brand-quicknode.png" alt="Logo Images"/>
                   </li>
                   <li>
                       <img src="/assets/images/brand/brand-yc.png" alt="Logo Images"/>
                   </li>
                   <li>
                       <img src="/assets/images/brand/brand-dojah.png" alt="Logo Images"/>
                   </li>
                   <li>
                       <img src="/assets/images/brand/brand-coinbase.png" alt="Logo Images"/>
                   </li>
                   <li>
                       <img src="/assets/images/brand/brand-visa.png" alt="Logo Images"/>
                   </li>
                   <li>
                       <img src="/assets/images/brand/brand-mt.png" alt="Logo Images"/>
                   </li>
                   <li>
                       <img src="/assets/images/brand/brand-veriff.png" alt="Logo Images"/>
                   </li>
                   <li>
                       <img src="/assets/images/brand/brand-resistance.png" alt="Logo Images"/>
                   </li>
               </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;