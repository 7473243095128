import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const Press = () => {
    return (
        <>
            <PageHelmet pageTitle='Press' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Press'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                
                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h2 className="tilte">Wallets Africa in the news</h2>
                                    
                                    <p>
                                        <a className="btn-transparent btn-purple" href="https://techcabal.com/2023/10/19/wallet-africa-introduces-spring-a-multi-currency-wallet/" target="_blank">Techcabal: Wallets Africa Introduces Spring: A Multi-Currency Wallet</a> &nbsp; &nbsp;
                                    </p>
                                    <p>
                                        <a className="btn-transparent btn-purple" href="https://disrupt-africa.com/2023/10/20/nigerias-wallets-africa-launches-multi-currency-wallet/" target="_blank">Disrupt Africa: Wallets Africa launches multi-currency wallet</a> &nbsp; &nbsp;
                                    </p>
                                    <p>
                                        <a className="btn-transparent btn-purple" href="https://www.youtube.com/watch?v=ZlpFZ8NqbsQ" target="_blank">Arise TV: John Oke Discuses Digital Wallet, 'Wallet.ng' and Its Many Benefits</a> &nbsp; &nbsp;
                                    </p>
                                    <p>
                                        <a className="btn-transparent btn-purple" href="https://techcrunch.com/2019/03/20/seven-africa-focused-startups-present-at-y-combinators-demo-day" target="_blank">Techcrunch: Seven Africa-focused startups present at Y Combinator’s Demo Day</a> &nbsp; &nbsp;
                                    </p>
                                    <p>
                                        <a className="btn-transparent btn-purple" href="https://techpoint.africa/2020/06/24/Wallets-africa-raises-fresh-capital-from-samurai-fund-mozilla-and-michael-seibel/" target="_blank">Techpoint: Wallets Africa raises fresh capital from Samurai Incubate, Mozilla, and Michael Seibel</a> &nbsp; &nbsp;
                                    </p>
                                    <p>
                                        <a className="btn-transparent btn-purple" href="https://techpoint.africa/2020/11/17/Wallets-africa-partners-visa-issue-cards/" target="_blank">Techpoint: Wallets Africa partners with VISA to help businesses issue cards to employees and customers</a> &nbsp; &nbsp;
                                    </p>
                                    <p>
                                        <a className="btn-transparent btn-purple" href="https://nairametrics.com/2021/02/25/john-oke-is-building-the-next-payment-solution-in-africa/" target="_blank">Nairametrics: John Oke is building the next payment solution in Africa</a> &nbsp; &nbsp;
                                    </p>
                                    <p>
                                        <a className="btn-transparent btn-purple" href="https://disrupt-africa.com/2020/06/29/nigerian-fintech-startup-Wallets-africa-secures-undisclosed-funding-round/" target="_blank">Disrupt Africa: Nigerian fintech startup Wallets Africa secures undisclosed funding round</a> &nbsp; &nbsp;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>
        
    )
}

export default Press;