import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ProgressOne from "../blocks/progressbar/ProgressOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import BrandTwo from "../elements/BrandTwo";
const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Secure and legible money',
        description: '',
        buttonText: '',
        buttonLink: 'https://forms.gle/MuXa1qSkZewWbtUFA'
    }
]

class HomePortfolio extends Component {
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <div className="active-dark bg_color--10"> 
                <Helmet pageTitle="Spring in Africa" />

                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--24" key={index} data-black-overlay="6">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title theme-primary">{value.title}</h1> : ''}
                                            {value.description ? <p className="text-white">{value.description}</p> : ''}
                                            {/* {value.buttonText ?  */}
                                            {/* // <div className="slide-btn"><a className="btn-default btn-border btn-opacity" target="_blank" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : */}
                                             {/* ''} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}
                
               
                
               
                
                 {/* Start portfolio Area  */}
                 <div className="rn-portfolio-area bg_color--10 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Fintech Innovation</span>
                                    <h2 className="title">Our brands</h2>
                                    <p className="description">Technology driving humankind forward <br /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <PortfolioOne item="8" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>
                        {/* <div className="row">
                            <div className="col-md-12">
                                <div className="view-more-btn mt--60 text-center">
                                    <a className="btn-default" href="/portfolio"><span>View More Project</span></a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* End portfolio Area  */}
                
               

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </div>
        )
    }
}
export default HomePortfolio;