import React, { Component } from "react";
import { Link } from 'react-router-dom';
const glycanage_image = <img src="/assets/images/portfolio/glycanage.jpeg" alt="Wallets" />;
const seventhpivot_image = <img src="/assets/images/portfolio/7thpivot.jpeg" alt="Wallets" />;
const spleet_image = <img src="/assets/images/portfolio/spleet.jpeg" alt="Wallets" />;
const payhippo_image = <img src="/assets/images/portfolio/Payhippo.jpg" alt="Wallets" />;
const workpay_image = <img src="/assets/images/portfolio/wk.jpeg" alt="Wallets" />;

const PortfolioList = [
    {
        image: workpay_image,
        category: 'SAAS',
        title: 'Workpay',
        description: 'Payroll software for businesses in Nigeria',
        link: '/workpay-details'
    },
    {
        image: payhippo_image,
        category: 'Lending',
        title: 'Payhippo',
        description: 'Providing loans to small and medium sized businesses in Nigeria',
        link: '/payhippo-details'
    },
    {
        image: spleet_image,
        category: 'Real Estate',
        title: 'Spleet',
        description: 'For renting and leasing houses in Nigeria',
        link: '/spleet-details'
    },
    {
        image: glycanage_image,
        category: 'Healthcare',
        title: 'GlycanAge',
        description: 'Biological age blood testing kit',
        link: '/glycanage-details'
    },
     
]


class PortfolioMasonry extends Component{
    render(){
        const {column } = this.props;
        const list = PortfolioList.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className="im_portfolio">
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to={value.link}>
                                        {value.image}
                                    </Link>    
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to={value.link}>{value.category}</Link>
                                        </div>
                                        <h4 className="title"><Link to={value.link}>{value.title}</Link></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            <Link className="transparent_link" to={value.link}></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioMasonry;