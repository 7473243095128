import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
const logoUrl = <img src="/assets/images/brand/spring.svg" alt="Digital Agency" />;

const SocialShare = [
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/company/wallets-companie'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/walletscompany/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/walletscompany'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area footer-style-01 bg_color--6">
                   
                    {/* Start Footer Area  */}
                    <div className="footer-wrapper ptb--70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="ft-text">
                                        <div className="logo">
                                            <Link to="/">
                                                {logoUrl}
                                            </Link>
                                            <p>Wallets Africa Inc.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                    
                                </div> 

                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                    <div className="footer-link">
                                        <h4>Say Hello</h4>
                                        <ul className="ft-link">
                                            <li>Email: <a href="mailto:hello@wallets.company">hello@wallets.company</a></li>
                                        </ul>
                                        <ul className="ft-link">
                                            <li>San Francisco, USA</li>
                                             
                                        </ul>
                                        <div className="social-share-inner mt--20">
                                            <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    {/* End Footer Area  */}
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;